import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { youtube } from 'scrape-youtube';

const StateContext = createContext();
const BASE_URL = 'https://www.googleapis.com/youtube/v3';


export const StateContextProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const fetchData = async (url) => {
    setLoading(true);
    setData([]);
    
    const data = await axios.get(`${BASE_URL}/${url}`, {
      params: {
        maxResults:10,
        channelId:'UCjuaFv7uCpZbMD2_Y-nn7Tg',
        type:'video',
        key:'AIzaSyC5m2P952Ck-M0gR0M-vJ3SDhawEKkOUtY',
        //AIzaSyCHYQ-aJdfiAY_SD0EMI3dPZvKAmTl9UNo
        
      },
    
    });

    setData(data?.data?.items);
    setLoading(false);
  };

  const fetchOtherData = async (url) => {
    const data1 = await axios.get(`${BASE_URL}/${url}`, {
      params: {
        maxResults:10,
        regionCode: 'BR',
        channelId:'UCjuaFv7uCpZbMD2_Y-nn7Tg',
        type:'video',
        order:'date',
        key:'AIzaSyCHYQ-aJdfiAY_SD0EMI3dPZvKAmTl9UNo',
      },
     
    });

    setResults(data1?.data?.items);
  };

  return (
    <StateContext.Provider value={{ fetchData, fetchOtherData, results, data, loading }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
