import React, { useEffect, useState } from 'react';
import { Typography, Box,Button} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ReactPlayer from 'react-player';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HorizontalScroll from 'react-scroll-horizontal';
import { categories } from '../categories';
import { useStateContext } from '../contexts/StateContextProvider';
import { VideoItem, Loader } from './'
import './prayer.css'

const VideoDetail = () => {
  const { id } = useParams();
  const { data, fetchData, fetchOtherData, results, loading } = useStateContext();
  const [videoDetail, setVideoDetail] = useState();
  const [selectedCategory, setSelectedCategory] = useState('loasstv');


  useEffect(() => {
    
    fetchData(`search?part=snippet&relatedToVideoId=${id}&type=video`);
    fetchOtherData(`videos?part=snippet,statistics&id=${id}`);
  }, [id]);

  useEffect(() => {
    setVideoDetail(results[0]);
  }, [results]);

  return (
    <>
    <Box sx={{ display: 'flex', gap: 5, overflow: 'auto', width: '100%', height: '100px', mt: 2 }} >
      <HorizontalScroll >
          
          {categories.map((category) => (
            <Button href='../../'
              className='category-btn'
              onClick={() => setSelectedCategory(category)}
              sx={{
                width: '150px',
                height: '40px',
                background: category === selectedCategory ? 'black' : '#F9F9F9',
                borderRadius: 20,
                color: category === selectedCategory ? 'white' : 'black',
                cursor: 'pointer',
                fontWeight: 600,
                mt: 1,
                ml: 1,
                textTransform: 'capitalize',
                textAlign:'center',
              }}
              key={category}
            >
              {category}
            </Button>
         
          ))}
      
      </HorizontalScroll>
      </Box>
      {videoDetail && (
       

       <div className='prayer-container'>
          {/* <ReactPlayer   controls={true} url={`https://www.youtube.com/watch?v=`} /> */}
          <iframe width="560" height="315" src={`https://www.youtube.com/embed/${id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
       </div>
       
      
      )}
    </>
  );
};

export default VideoDetail;
