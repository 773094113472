import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import HorizontalScroll from 'react-scroll-horizontal';

import { useStateContext } from '../contexts/StateContextProvider';
import { categories } from '../categories';
import { categories2 } from '../categories2';
import { VideoItem, Loader } from './';
import { slide as Menu } from "react-burger-menu";

export default props => {
    const [selectedCategory, setSelectedCategory] = useState('loasstv');
    const { fetchData, data, loading } = useStateContext();
  
    useEffect(() => {
      if (selectedCategory=='RECENTES') {
        fetchData(`search?order=date&part=snippet&q=LoassTV`);
      } 
      else if(selectedCategory=='RADIO MAIS'){
        window.location.href = "https://www.redemaisfm.com.br/ao-vivo/player.php/mais-fm-brasil"
      }
      else if(selectedCategory=='RADIO LOASSTV'){
        window.location.href = "https://radio.loasstv.com.br/"
       }
      else {
        fetchData(`search?part=snippet&q=intitle:"${selectedCategory}"`);
      }
      
      
     
    }, [selectedCategory]);
  return (
    // Pass on our props
    <Menu {...props}>
     {categories.map((category) => (
            <Button
              className='category-btn'
              onClick={() => setSelectedCategory(category)}
              sx={{
                width: '100%',
                height: '50px',
                background: category === selectedCategory ? 'black' : '#F9F9F9',
                
                color: category === selectedCategory ? 'white' : 'black',
                cursor: 'pointer',
                fontWeight: 600,
                mt: 1,
                ml: 1,
                textTransform: 'capitalize',
              }}
              key={category}
            >
              {category}
            </Button>
         
          ))}
    </Menu>
  );
};
