import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import { SearchBar } from './';
import { useStateContext } from '../contexts/StateContextProvider';
import { categories } from '../categories';
import SideBar from "./sidebar";
//typography style
//sx={{ fontSize: {sm: 20, md: '25px',marginTop:'13px' }, color: 'ORANGE', fontWeight: 800 }}

const Navbar = () =>  (

 
  
  <Box sx={{ display: 'flex', justifyContent: 'space-between',  borderBottom: 'px solid black', position: 'relative', top:0, left: 0, background:'', width: '100%', zIndex: 100 }}>
  
    <Link to='/' style={{ textDecoration: 'none' }}>
    <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
      <Typography >
      
      <img src={logo} alt="Logo" style={{ height: '40px' }} />
      </Typography>
    </Link>
    <SearchBar />
    

  </Box>
);

export default Navbar;
