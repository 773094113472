import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { ChannelDetail, VideoDetail, SearchFeed, Navbar, Feed } from './components';
import bandeira from './components/bandeira.jpeg';
import "./components/styles.css";
import SideBar from "./components/sidebar";






const App = () => (
  
  <Router>
    
    <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid black', position: 'flex', top: 100, left: 0, background:'#181a1b', width: '100%', zIndex: 100 }}></Box>
    <Box sx={{ p: 0 }}>
      
      <Navbar />
      <img class="center" style={{top:50,display:'block',margin:'0 auto',width:'99%', height: '10%',border:'3px solid black' }} src={bandeira} alt="bandeira"/>
    
      <Switch>
        <Route exact path='/' component={Feed} />
        <Route path='/video/:id' component={VideoDetail} />
        <Route path='/channel/:id' component={ChannelDetail} />
        <Route path='/search' component={SearchFeed} />
      </Switch>
    </Box>
  </Router>
);

export default App;